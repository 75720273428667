import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import DefaultTemplate from "@src/site/templates/default"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import {
  detectAlpha2Country,
  detectAlpha2LanguageInPath,
} from "@src/utils/country"
import { getQueryParameter } from "@src/utils/general"
import { Container } from "@material-ui/core"
// import { createCookie, isPresent } from '@src/utils/cookie';
import cookie from "@src/utils/cookie"

// components
import Progress from "@src/components/Progress"
import Header from "@src/modules/HeaderModule"
import Footer from "@src/modules/FooterModule"
import Module from "../components/Module"

const { snake } = require(`../snake/core`)

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "1400px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  rowFlex: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  widthCol: {
    flexBasis: "33.3333333333%",
    maxWidth: "33.3333333333%",
  },
  title: {
    fontWeight: "600",
    fontSize: "40px",
    color: "#662482",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
  },
  mb8: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: "18px",
    color: "#3b4154",
    fontStyle: "normal",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    textDecoration: "none",
    fontWeight: "normal",
  },
}))

export default function WebMap() {
  const [pageModules, setPageModules] = useState({
    header: {},
    footer: {},
  })
  const [gdprRequest, setGdprRequest] = useState({
    loading: true,
    success: false,
  })
  const [errorMessage, setErrorMessage] = useState("")
  const classes = useStyles()

  const { i18n, t } = useTranslation()
  const country = detectAlpha2Country()
  const lang = detectAlpha2LanguageInPath()
  const [indexPages, setIndexPages] = useState([])

  const page = {
    content: null,
    tracking: {},
    seo: {},
  }

  useEffect(() => {
    async function fetchData() {
      let site = t("site")
      const headerResult = await snake.getSectionByType("header", site)
      const footerResult = await snake.getSectionByType("footer", site)
      const indexPagesResult = await snake.getIndexesPages(site)

      setIndexPages(indexPagesResult.data)

      setPageModules({
        header: {
          ...headerResult.data.content,
          repo: { black: false, dark: true },
        },
        footer: {
          ...footerResult.data.content,
          css: footerResult.data.css,
        },
      })
    }

    fetchData()
  }, [])

  return (
    <DefaultTemplate page={page}>
      <Container>
        <Module>
          <section className={`webMap`} style={{ marginTop: "64px" }}>
            <div className="row">
              <div className={classes.container}>
                <div className={classes.rowFlex}>
                  {indexPages?.titles?.map((title, index) => {
                    return (
                      <div key={index} className={classes.widthCol}>
                        <h1 className={classes.title}>
                          {title === "other"
                            ? t("index_page_other")
                            : title === "past-collection"
                            ? t("index_page_past_collection")
                            : title}
                        </h1>
                        {indexPages?.content[title].map((data, index) => {
                          return (
                            <div key={index} className={classes.mb8}>
                              <a href={data.url} className={classes.subtitle}>
                                {data.title}
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
        </Module>
      </Container>
      <Footer {...pageModules.footer} />
    </DefaultTemplate>
  )
}
